import ListModelBase from "@/apps/core/models/listModelBase.js";

const modelName = "anggotaList";
const modelPath = "/anggota/";

class AnggotaList extends ListModelBase {
  constructor() {
    super(modelName, modelPath);
  }
}

export default AnggotaList;