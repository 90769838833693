<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">DAFTAR ANGGOTA</p>
          <router-link
            v-if="isAdmin"
            :to="{ name: 'preceptor-add' }"
            class="card-header-icon has-text-light"
            title="Tambah anggota"
          >
            <b-icon class="has-text-light" icon="plus" />
          </router-link>
          <b-upload
            v-if="isAdminPusat"
            :key="`file_${fileCounter}`"
            v-model="fl"
            @input="() => upload()"
            accept=".xlsx"
          >
            <a title="Upload" class="card-header-icon has-text-light">
              <b-icon icon="upload"></b-icon>
            </a>
          </b-upload>
          <a
            v-if="isAdminPusat"
            title="Download"
            class="card-header-icon has-text-light"
            @click.stop.prevent="download()"
          >
            <b-icon icon="download" pack="fas" />
          </a>
        </header>
        <div class="card-content table-card-content">
          <b-field
            position="is-right"
            class="filter-field"
            grouped
            group-multiline
          >
            <cari-input class="filter1" v-model="filterMap.cari"></cari-input>
            <filter-button @apply-filter="applyFilter"></filter-button>
          </b-field>

          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
          <div class="box-container">
            <small
              v-if="anggotaList.length > 0"
              class="page-info is-pulled-right"
              ><strong>{{ pagination.startRowNum }}</strong> -
              <strong>{{
                pagination.startRowNum + anggotaList.length - 1
              }}</strong>
              dari <strong>{{ pagination.total }}</strong></small
            >
            <div class="cleardiv"></div>
            <box
              v-for="anggota in anggotaList"
              :key="anggota.id"
              :title="anggota.nama"
              :subtitle1="anggota.user"
              :subtitle2="anggota.no_hp"
              :detailUrl="{
                name: 'anggota-detail',
                params: { id: anggota.id },
              }"
            ></box>
            <box v-if="anggotaList.length == 0">
              <template v-slot:content>
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>Tidak ada data.</p>
                  </div>
                </section>
              </template>
            </box>
          </div>

          <!-- <b-table
            bordered
            striped
            hoverable
            :loading="loading"
            :data="anggotaList"
            :mobile-cards="false"
          >
            <b-table-column
              field="no"
              label="No"
              v-slot="props"
            >{{ pagination.startRowNum + props.index }}</b-table-column>
            <b-table-column field="nama" label="Nama" v-slot="props">
              <router-link
                :to="{ name: 'anggota-detail', params: { id: props.row.id } }"
              >{{ props.row.nama }}</router-link>
            </b-table-column>
            <b-table-column field="no_hp" label="No Hp" v-slot="props">{{ props.row.no_hp }}</b-table-column>
            <b-table-column field="email" label="Email" v-slot="props">{{ props.row.user }}</b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>Tidak ada data.</p>
                </div>
              </section>
            </template>
          </b-table>-->

          <b-pagination
            v-if="pagination.showPagination"
            :total="pagination.total"
            :per-page="pagination.limit"
            :current.sync="pagination.page"
            order="is-centered"
            icon-pack="fas"
            @change="pageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import APP_CONFIG from "@/apps/core/modules/config";
import axios from "axios";
import AnggotaExcel from "../modules/anggotaExcel";
import { mapGetters } from "vuex";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import fetchActivatedMixin from "@/apps/core/mixins/fetchActivatedMixin";
import paginationMixin from "@/apps/core/mixins/paginationMixin";
import AnggotaList from "../models/anggotaList.js";

export default {
  name: "AnggotaList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    CariInput: () => import("@/apps/core/components/CariInput.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
  },
  data() {
    this.anggotaListMdl = new AnggotaList();
    this.listMdl = this.anggotaListMdl; // bikin alias
    const apiUrl = `${APP_CONFIG.baseAPIURL}/anggota/batch/`;
    this.anggotaExcel = new AnggotaExcel(
      () => {
        this.fl = null;
        this.fetchData();
      },
      () => (this.loading = false)
    );
    this.anggotaExcel.setFilename("data_anggota.xlsx");
    this.anggotaExcel.setApiUrl(apiUrl);
    let obv = this.anggotaListMdl.getObservables();
    obv.fl = null;
    obv.fileCounter = 0;
    return obv;
  },
  computed: {
    ...mapGetters("accounts", ["isAdmin", "isAdminPusat"]),
  },
  methods: {
    download() {
      this.anggotaExcel.exportFile();
    },
    upload() {
      this.loading = true;
      this.anggotaExcel.importFile(this.fl);
      this.fileCounter++;
    },
    loadAngkatan() {
      const apiURL = `${APP_CONFIG.baseAPIURL}/programstudi/angkatan/options/`;
      axios
        .get(apiURL)
        .then((response) => {
          let angkatanList = [
            `"${response.data.map((angkatan) => angkatan.nama).join()}"`,
          ];
          this.anggotaExcel.setAngkatanList(angkatanList);
        })
        .catch(() => {
          this.anggotaExcel.setAngkatanList([]);
        });
    },
    loadDepartemen() {
      const apiURL = `${APP_CONFIG.baseAPIURL}/programstudi/subdepartemen/`;
      axios
        .get(apiURL)
        .then((response) => {
          let departemenList = [
            `"${response.data.map((departemen) => departemen.nama).join()}"`,
          ];
          this.anggotaExcel.setDepartemenList(departemenList);
        })
        .catch(() => {
          this.anggotaExcel.setDepartemenList([]);
        });
    },
    fetchData() {
      this.listMdl.load();
      this.loadDepartemen();
      this.loadAngkatan();
    },
  },
  mixins: [onlineCheckMixin, fetchActivatedMixin, paginationMixin],
};
</script>

<style scoped lang="scss">
@import "@/apps/core/assets/scss/listview.scss";
</style>
